import React from 'react';
import { useIntl, IntlContextConsumer } from 'gatsby-plugin-intl';
import { graphql, Link } from 'gatsby';
import styled from '@emotion/styled';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import { ModalRoutingContext } from 'gatsby-plugin-modal-routing-3';
import { Container, Layout, Seo } from '../components';
import '../styles/portfolioTemplate.css';
import { useTheme } from '../providers/themes/Context';

const PortfolioPage = styled.div((props) => ({
  backgroundColor: props.theme.primaryColor,
  color: props.theme.text,
}));

const StyledLayout = styled(Layout)((props) => ({
  height: 'auto',
}));

const CloseButton = styled(Link)((props) => ({
  borderBottom: `solid 1px ${props.theme.lines}`,
  borderLeft: `solid 1px ${props.theme.lines}`,
}));

const ButtonLine = styled.div((props) => ({
  backgroundColor: props.theme.lines,
}));

const BackLink = styled(Link)((props) => ({
  color: props.theme.text,
}));

const NavigationButton = styled(Link)((props) => ({
  border: `solid 1px ${props.theme.lines}`,
}));

const PortfolioPageComponent = ({
  portfolio,
  nextSlug,
  previousSlug,
  locale,
}) => {
  const intl = useIntl();
  const { theme } = useTheme();
  const heroImage = getImage(portfolio.heroImage);
  const tags = portfolio.metadata?.tags.map((tag) =>
    tag.name.replace('Portfolio: ', ''),
  );

  return (
    <ModalRoutingContext.Consumer>
      {({ modal, closeTo }) => (
        <IntlContextConsumer>
          {({ language: currentLocale }) => (
            <PortfolioPage {...{ theme }} className="singlePortfolio">
              {modal && (
                <CloseButton
                  {...{ theme }}
                  to={closeTo}
                  className="closeButton"
                >
                  <ButtonLine {...{ theme }} className="line left" />
                  <ButtonLine {...{ theme }} className="line right" />
                </CloseButton>
              )}
              <Container>
                <div className="singlePortfolio__hero">
                  <div className="portfolioHero__content">
                    <h1 className="portfolioHero__title">{portfolio.title}</h1>
                    {tags.length > 0 && (
                      <div className="portfolioHero__tags">
                        {tags.join(' / ')}
                      </div>
                    )}
                    <div className="portfolioHero__description">
                      {portfolio.description &&
                        renderRichText(portfolio.description)}
                    </div>
                  </div>
                  <GatsbyImage
                    className="portfolioHero__image"
                    image={heroImage}
                    alt={portfolio.heroImage.title}
                  />
                </div>

                {portfolio.imagesRows && portfolio.imagesRows.length > 0 && (
                  <div className="singlePortfolio__images">
                    {portfolio.imagesRows.map((imageRow) => {
                      let imagesPerRowClass = '';
                      const imagesPerRow = imageRow.images.length;
                      switch (imagesPerRow) {
                        case 3:
                          imagesPerRowClass = 'triple';
                          break;
                        case 2:
                          imagesPerRowClass = 'double';
                          break;
                        case 1:
                        default:
                          imagesPerRowClass = 'single';
                          break;
                      }
                      return (
                        <div
                          key={imageRow.id}
                          className={`imageRow ${imagesPerRowClass}`}
                        >
                          {imageRow.images.map((image) => {
                            const processedImage = getImage(image);
                            return (
                              <GatsbyImage
                                className="imageRow__image"
                                key={image.id}
                                image={processedImage}
                                alt={image.title}
                              />
                            );
                          })}
                        </div>
                      );
                    })}
                  </div>
                )}

                <div className="singlePortfolio__actions">
                  {previousSlug && (
                    <NavigationButton
                      {...{ theme }}
                      className="navigationButton previous"
                      to={`../${previousSlug}`}
                      state={{
                        modal: modal,
                      }}
                    >
                      <ButtonLine {...{ theme }} className="inclined top" />
                      <ButtonLine {...{ theme }} className="horizontal" />
                      <ButtonLine {...{ theme }} className="inclined bottom" />
                    </NavigationButton>
                  )}

                  {modal ? (
                    <BackLink
                      className="singlePortfolio__back"
                      to={closeTo}
                      {...{ theme }}
                    >
                      {intl.formatMessage({ id: 'backPortfolio' })}
                    </BackLink>
                  ) : (
                    <BackLink
                      className="singlePortfolio__back"
                      to="/"
                      {...{ theme }}
                    >
                      {intl.formatMessage({ id: 'backHome' })}
                    </BackLink>
                  )}

                  {nextSlug && (
                    <NavigationButton
                      {...{ theme }}
                      className="navigationButton next"
                      to={`../${nextSlug}`}
                      state={{
                        modal: modal,
                      }}
                    >
                      <ButtonLine {...{ theme }} className="inclined top" />
                      <ButtonLine {...{ theme }} className="horizontal" />
                      <ButtonLine {...{ theme }} className="inclined bottom" />
                    </NavigationButton>
                  )}
                </div>
              </Container>
            </PortfolioPage>
          )}
        </IntlContextConsumer>
      )}
    </ModalRoutingContext.Consumer>
  );
};

export default function SinglePortfolio({ data, location, pageContext }) {
  const { contentfulPortfolio: portfolio } = data;
  console.log({ portfolio });
  const { nextSlug, previousSlug, locale } = pageContext;
  console.log({ locale });

  return (
    <ModalRoutingContext.Consumer>
      {({ modal }) =>
        modal ? (
          <PortfolioPageComponent
            nextSlug={nextSlug}
            previousSlug={previousSlug}
            locale={locale}
            {...{ portfolio }}
          />
        ) : (
          <StyledLayout location={location}>
            <Seo title={portfolio.title} />
            <PortfolioPageComponent
              nextSlug={nextSlug}
              previousSlug={previousSlug}
              locale={locale}
              {...{ portfolio }}
            />
          </StyledLayout>
        )
      }
    </ModalRoutingContext.Consumer>
  );
}

export const query = graphql`
  query getPortfolioItem($slug: String!, $locale: String) {
    contentfulPortfolio(slug: { eq: $slug }, node_locale: { eq: $locale }) {
      id
      title
      metadata {
        tags {
          id
          name
        }
      }

      heroImage {
        gatsbyImageData(
          width: 852
          height: 691
          quality: 90
          layout: FULL_WIDTH
        )
        title
      }
      description {
        raw
      }
      imagesRows {
        images {
          gatsbyImageData(width: 1276, quality: 90, layout: FULL_WIDTH)
          id
          title
        }
        id
        name
      }
    }
  }
`;
